import { NodeEnv } from '~/app/common/constants'
import useIsEnvironment from '~/app/hooks/useIsEnvironment'
import LogoImage from '~/app/assets/img/logo.png'
import { Heading, Link } from './styled'

interface Props {
  textColor: 'light' | 'dark'
}

export default function Logo(props: Props) {
  const isProduction = useIsEnvironment(NodeEnv.production)

  return (
    <Link to="/">
      <img src={LogoImage} alt="Little Spoon Logo" />
      <Heading color={props.textColor}>
        Spoon Central
        {!isProduction && (
          <>
            <br />
            {process.env.NODE_ENV}
          </>
        )}
      </Heading>
    </Link>
  )
}
