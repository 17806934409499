import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.dark};
  font-size: 2rem;
  padding: 0.75rem;
  border-radius: 0.8rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.color.lightGray};
  }
`
