import styled from 'styled-components'
import { colors } from '~/app/settings/colors'

export const Wrapper = styled.div``

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin: 3rem 0;

  ul {
    display: flex;
    justify-content: right;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    background-color: #fff;
    border: solid 1px ${`${colors.default}25`};
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 3px;

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 24px;
      justify-content: center;
      width: 24px;
    }

    &.selected {
      background-color: ${colors.default};
      color: #fff;
    }
  }
`
