import styled, { css } from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div``

export const Notice = styled.p`
  color: ${(props) => props.theme.color.darkGray};
  font-family: 'Lato', sans-serif;
  padding: 20px 0 40px;
  text-align: center;
  width: 100%;
`

export const OrderGroups = styled.ul`
  list-style: none;
  padding: 0;
`

export const OrderGroup = styled.li``

export const TableBody = styled.tbody`
  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`

export const OrderGroupHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  justify-content: space-between;
  position: relative;
  padding-bottom: 1.5rem;
  margin-top: 2rem;

  ${media.lg} {
    flex-direction: row;
    align-items: flex-end;
  }
`

export const OrderGroupDate = styled.h2`
  margin-bottom: 0.2rem;
  font-size: 2rem;

  ${media.lg} {
    font-size: 2.2rem;
  }
`

export const OrderGroupActions = styled.div``

export const OrderGroupTextColumn = styled.div`
  display: flex;
  flex-direction: column;
`
