import { Switch, Route, Redirect } from 'react-router-dom'
import BabyBlendsPage from '~/app/pages/Products/Babyblends/Loadable'
import BlueprintUseCasePage from '~/app/pages/BlueprintUseCase/Loadable'
import BlueprintUseCasesPage from '~/app/pages/BlueprintUseCases/Loadable'
import BoxesByStatePage from '~/app/pages/Operations/ActiveBoxesByState/Loadable'
import BoxesByStatesPage from '~/app/pages/Operations/ActiveBoxesByStates/Loadable'
import DietaryTagPage from '~/app/pages/ProductMerchandising/DietaryTag/Loadable'
import DietaryTagsPage from '~/app/pages/ProductMerchandising/DietaryTags/Loadable'
import FormFactorsPage from '~/app/pages/ProductMerchandising/FormFactors/Loadable'
import FormFactorPage from '~/app/pages/ProductMerchandising/FormFactor/Loadable'
import IngredientPage from '~/app/pages/ProductMerchandising/Ingredient/Loadable'
import IngredientsPage from '~/app/pages/ProductMerchandising/Ingredients/Loadable'
import InvalidateCachePage from '~/app/pages/DeveloperTools/InvalidateCache/Loadable'
import LabelPage from '~/app/pages/ProductMerchandising/Label/Loadable'
import LabelsPage from '~/app/pages/ProductMerchandising/Labels/Loadable'
import MiscPage from '~/app/pages/Products/Misc/Loadable'
import NotFoundPage from '~/app/pages/NotFound/Loadable'
import PlatesPage from '~/app/pages/Products/Plates/Loadable'
import BiteablesPage from '~/app/pages/Products/Biteables/Loadable'
import LunchersPage from '~/app/pages/Products/Lunchers/Loadable'
import SnacksPage from '~/app/pages/Products/Snacks/Loadable'
import PuffsPage from '~/app/pages/Products/Puffs/Loadable'
import CerealPage from '~/app/pages/Products/Cereal/Loadable'
import ProductPage from '~/app/pages/Product/Loadable'
import PromoCodesPage from '~/app/pages/PromoCodes/Loadable'
import ShippingBoxPage from '~/app/pages/Operations/ShippingBox/Loadable'
import ShippingBoxesPage from '~/app/pages/Operations/ShippingBoxes/Loadable'
import CartonizeOrdersPage from '~/app/pages/Operations/CartonizeOrders/Loadable'
import PaccurateConfigPage from '~/app/pages/Operations/PaccurateConfig/Loadable'
import SmoothiesPage from '~/app/pages/Products/Smoothies/Loadable'
import PromoBannersPage from '~/app/pages/PromoBanners'
import PromoBannerPage from '~/app/pages/PromoBanner'
import UserPage from '~/app/pages/User'
import UsersPage from '~/app/pages/Users/Loadable'
import useIsEnvironment from '~/app/hooks/useIsEnvironment'
import { NodeEnv } from '~/app/common/constants'
import ProductDefaultsCategory from '~/app/pages/ProductDefaultsCategory'
import ProductDefaults from '~/app/pages/ProductDefaults'
import productTypeSlug from '~/app/common/dataMaps/productTypeSlug'
import { ProductType } from '~/app/common/types'

export default function Routes() {
  const isProduction = useIsEnvironment(NodeEnv.production)
  return (
    <Switch>
      {/* PRODUCT DEFAULTS */}
      <Route exact path="/product-defaults" component={ProductDefaults} />
      <Route exact path="/product-defaults/:type" component={ProductDefaultsCategory} />

      {/* BLUEPRINTS */}
      <Redirect exact from="/blueprint" to="/blueprint/usecases" />
      <Route exact path="/blueprint/usecases" component={BlueprintUseCasesPage} />
      <Route exact path="/blueprint/usecases/:blueprintId?" component={BlueprintUseCasePage} />
      <Route exact path="/promocodes/:activeTab?" component={PromoCodesPage} />

      {/* DEVELOPER TOOLS */}
      <Redirect exact from="/developertools" to="developertools/invalidatecache" />
      <Route exact path="/developertools/invalidatecache" component={InvalidateCachePage} />

      {/* OPERATIONS */}
      <Redirect exact from="/operations" to="operations/shipping-boxes" />
      <Route exact path="/operations/shipping-boxes" component={ShippingBoxesPage} />
      <Route exact path="/operations/shipping-boxes/:boxId?" component={ShippingBoxPage} />
      <Route exact path="/operations/boxes-by-state" component={BoxesByStatesPage} />
      <Route exact path="/operations/boxes-by-state/:state?" component={BoxesByStatePage} />
      {!isProduction && (
        <Route exact path="/operations/cartonize-orders" component={CartonizeOrdersPage} />
      )}
      <Route exact path="/operations/paccurate-config" component={PaccurateConfigPage} />

      {/* PRODUCT */}
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.blend]}`}
        component={BabyBlendsPage}
      />
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.plate]}`}
        component={PlatesPage}
      />
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.biteable]}`}
        component={BiteablesPage}
      />
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.luncher]}`}
        component={LunchersPage}
      />
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.snack]}`}
        component={SnacksPage}
      />
      <Route exact path={`/products/${productTypeSlug[ProductType.puff]}`} component={PuffsPage} />
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.cereal]}`}
        component={CerealPage}
      />
      <Route
        exact
        path={`/products/${productTypeSlug[ProductType.smoothie]}`}
        component={SmoothiesPage}
      />
      <Route exact path={`/products/${productTypeSlug[ProductType.misc]}`} component={MiscPage} />
      <Redirect exact from="/products" to={`/products/${productTypeSlug[ProductType.blend]}`} />
      <Route exact path="/products/:productId?&:type?" component={ProductPage} />

      {/* PRODUCT MERCHANDISING */}
      <Redirect exact from="/merchandising" to="merchandising/labels" />
      <Route exact path="/merchandising/labels" component={LabelsPage} />
      <Route exact path="/merchandising/labels/:labelId" component={LabelPage} />
      <Route exact path="/merchandising/dietary-tags" component={DietaryTagsPage} />
      <Route exact path="/merchandising/dietary-tags/:tagId" component={DietaryTagPage} />
      <Route exact path="/merchandising/ingredients" component={IngredientsPage} />
      <Route exact path="/merchandising/ingredients/:ingredientId?" component={IngredientPage} />
      <Route exact path="/merchandising/form-factors" component={FormFactorsPage} />
      <Route exact path="/merchandising/form-factors/:formFactorId" component={FormFactorPage} />

      {/* USERS */}
      <Redirect exact from="/" to="/users/list" />
      <Route exact path="/users/list/:type?" component={UsersPage} />
      <Route exact path="/users/:userId" component={UserPage} />
      <Route path="/users/:userId/:activeTab?" component={UserPage} />

      <Route exact path="/promo-banners" component={PromoBannersPage} />
      <Route exact path="/promo-banners/:bannerId" component={PromoBannerPage} />

      {/* NOT FOUND */}
      <Route component={NotFoundPage} />
    </Switch>
  )
}
