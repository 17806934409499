import { useEffect } from 'react'

import useHasUserRole from '~/app/hooks/useHasUserRole'
import { useMappedDispatch, useMappedState } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'
import plans$ from '~/app/store/plans'
import pricingGroup$ from '~/app/store/pricingGroup'
import Page from '~/app/components/Page'
import Tabs from '~/app/components/Tabs'
import { Title } from '~/app/components/Titles/PageTitle'
import { UserRole } from '~/app/common/constants'
import DeliverySchedule from './DeliverySchedule'
import UserOrders from './UserOrders'
import UserInfo from './UserInfo'
import UserHeader from './UserHeader'
import UserCredits from './UserCredits'
import KidsAndPlans from './KidsAndPlans'
import UserLogs from './UserLogs'
import { fetchProducts } from '~/app/store/products/actions'
import type { Plan } from '~/app/store/plans/types'

interface Props {
  match: {
    params: any
  }
}

export default function User(props: Props) {
  const self = useController(props)

  if (!self.userId || !self.hasRole || !self.user || !self.userFeatureFlags?.data) {
    return null
  }

  return (
    <Page>
      <Title>Edit User</Title>
      <UserHeader
        // @ts-ignore
        user={self.user}
        subscriptions={self.subscriptions}
      />
      <Tabs
        activeTab={props.match.params.activeTab}
        tabs={[
          // USER DETAIL
          {
            content: <UserInfo user={self.user} subscriptions={self.subscriptions} />,
            name: 'details',
            title: 'User Detail',
            to: `/users/${self.userId}`,
          },

          // KIDS AND PLANS
          {
            content: (
              <KidsAndPlans
                // @ts-ignore
                user={self.user}
                subscriptions={self.subscriptions}
                plans={self.plans}
              />
            ),
            name: 'kids',
            title: 'Kids & Plans',
            to: `/users/${self.userId}/kids`,
          },

          // USER CREDITS
          {
            content: <UserCredits />,
            name: 'credits',
            title: 'Credits + Coupons',
            to: `/users/${self.userId}/credits`,
          },

          // DELIVERY SCHEDULE
          {
            // @ts-ignore
            content: <DeliverySchedule user={self.user} subscriptions={self.subscriptions} />,

            name: 'delivery',

            title: 'Delivery Schedule',
            to: `/users/${self.userId}/delivery`,
          },

          {
            content: (
              <UserOrders
                // @ts-ignore
                userId={self.userId}
                orders={self.userOrders}
                plans={self.plans}
              />
            ),
            name: 'orders',
            title: 'Orders',
            to: `/users/${self.userId}/orders`,
          },

          {
            content: <UserLogs userId={self.userId} page={0} />,
            name: 'logs',
            title: 'Logs',
            to: `/users/${self.userId}/logs`,
          },
        ]}
      />
    </Page>
  )
}

function useController(props: Props) {
  const { userId } = props.match.params
  const hasRole = useHasUserRole([UserRole.superadmin, UserRole.admin, UserRole['user-manager']])
  const dispatcher = useMappedDispatch({ fetchProducts })
  const { plans, subscriptions, user, userFeatureFlags, userOrders } = useMappedState(
    (state: Record<string, any>) => ({
      plans: plans$.getPlans(state) as Plan[],
      subscriptions: user$.getUserSubscriptions(state),
      user: user$.getDetails(state),
      userFeatureFlags: user$.getUserFeatureFlags(state),
      userOrders: user$.getPastOrders(state),
    }),
  )

  useEffect(() => {
    user$.call.fetchUserFeatureFlags(userId)
    plans$.call.fetchAllPlans()
    pricingGroup$.call.fetchPricingGroup(userId)
    dispatcher.fetchProducts({})
  }, [])

  useEffect(() => {
    if (userFeatureFlags?.data) {
      user$.call.fetchUserDetails(userId)
      user$.call.fetchUserSubscriptions(userId)
      user$.call.fetchOrders(userId)
    }
  }, [userFeatureFlags?.data])

  return {
    userId,
    hasRole,
    plans,
    subscriptions,
    user,
    userFeatureFlags,
    userOrders,
  }
}
