import styled from 'styled-components'
import Button from '~/app/components/Button/Button'
import media from '~/app/utils/mediaQueries'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
  flex-direction: column;

  ${media.lg} {
    flex-direction: row;
  }
`

export const SubmitButton = styled(Button)`
  margin-top: 20px;
`
