import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  ${media.lg} {
    align-items: flex-start;
  }
`

export const Row = styled.div`
  display: flex;
  padding: 3px 0;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  select {
    margin-right: 18px;
  }

  > div:nth-child(2) {
    margin-right: 18px;
  }

  ${media.lg} {
    gap: 0;
    flex-direction: row;
    align-items: flex-start;
  }
`

export const DeleteButton = styled.button`
  border: 0;
  height: 50px;
`
