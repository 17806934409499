import useLoader from '~/app/hooks/useLoader'
import { ListTable, Header, InfoRow, TableHead, TableRow, TableBody } from '~/app/components/Table'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { useState, useEffect } from 'react'
import user$ from '~/app/store/user'
import { UserLogsStatus, type RootState } from '~/app/store/user/logs/types'
import { switchOn, option, defaultOption } from '~/app/utils/switchOn'
import Log from './Log'
import { Wrapper, PaginationWrapper } from './styled'
import { Pagination } from './Pagination'

interface Props {
  userId: number
  page: number
}

const defaultMaxPages = 4

export default function UserLogs(props: Props) {
  const self = useController(props)

  return (
    <Wrapper>
      <PaginationWrapper>
        <Pagination
          activePage={self.currentPage}
          pageCount={Math.max(self.currentPage + 1, defaultMaxPages)}
          pageRangeDisplayed={defaultMaxPages}
          onClick={self.handlePageClick}
          onPageChange={self.handlePageChange}
        />
      </PaginationWrapper>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header>Timestamp</Header>
            <Header>Event Type</Header>
            <Header>Description</Header>
            <Header>Metadata</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {switchOn(
            option(self.isLoading && self.loader),
            option(
              self.logs.length &&
                self.logs.map(({ key, timestamp, eventType, description, metadata }) => (
                  <Log
                    key={key}
                    timestamp={timestamp}
                    eventType={eventType}
                    description={description}
                    metadata={metadata}
                  />
                )),
            ),
            defaultOption(<InfoRow>No logs found.</InfoRow>),
          )}
        </TableBody>
      </ListTable>
      {!self.isLoading && self.logs.length ? (
        <PaginationWrapper>
          <Pagination
            activePage={self.currentPage}
            pageCount={Math.max(self.currentPage + 1, defaultMaxPages)}
            pageRangeDisplayed={defaultMaxPages}
            onClick={self.handlePageClick}
            onPageChange={self.handlePageChange}
          />
        </PaginationWrapper>
      ) : null}
    </Wrapper>
  )
}

function useController(props: Props) {
  const [pageParam, setPageParam] = usePageFromUrl()
  const [currentPage, setCurrentPage] = useState(pageParam || props.page)
  const { logs, loader, isLoading } = useLogs(props.userId, currentPage)
  const handlePageClick = ({ isNext }: { isNext: boolean }) => {
    if (isNext && currentPage + 1 >= defaultMaxPages && logs.length) {
      setPageParam(currentPage + 1)
      setCurrentPage(currentPage + 1)
    }
  }
  const handlePageChange = ({ selected }: { selected: number }) => {
    setPageParam(selected)
    setCurrentPage(selected)
  }

  return {
    logs,
    loader,
    isLoading,
    currentPage,
    handlePageClick,
    handlePageChange,
  }
}

function useLogs(userId: number, page: number) {
  const logState = useMappedState(
    (state: object & { user: RootState }) => state.user.logs as RootState['logs'],
  )
  const isLoading = logState.status !== UserLogsStatus.loaded
  const loader = useLoader(isLoading)
  const logs = logState.data.map(({ id, timestamp, eventType, description, metadata }) => ({
    key: id,
    timestamp: new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }),
    eventType,
    description,
    metadata: JSON.stringify(metadata),
  }))

  useEffect(() => {
    user$.call.fetchUserAuditLogs({ userId, page })
  }, [userId, page])

  return { logs, loader, isLoading }
}

function usePageFromUrl(): [number | null, (page: number) => any] {
  const urlParams = new URLSearchParams(window.location.search)
  const pageParam = Number(urlParams.get('page'))
  const setPageParam = (page: number) => window.history.replaceState(null, 'page', `?page=${page}`)

  return [isNaN(pageParam) ? null : pageParam, setPageParam]
}
