import moment from 'moment-timezone'
import { Cell, TableRow } from '~/app/components/Table'
import { TagName } from './styled'

type $Props = {
  id: number
  title: string
  startDate: string
  endDate: string
  onClick: () => void
}

export default function Item({ id, title, startDate, endDate, onClick }: $Props) {
  return (
    <TableRow onClick={onClick} isClickable>
      <Cell>{id}</Cell>
      <Cell>
        <TagName>{title}</TagName>
      </Cell>
      <Cell>
        <TagName>{moment(startDate).tz('America/New_York').format('M/D/YYYY, h:mma z')}</TagName>
      </Cell>
      <Cell>
        <TagName>{moment(endDate).tz('America/New_York').format('M/D/YYYY, h:mma z')}</TagName>
      </Cell>
    </TableRow>
  )
}
