import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function fetchUserCredits(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    try {
      const response = await Api.fetchUserCredits(userId)
      await store.dispatch(
        self$.setUserCredits({
          balance: response.balance,
          history: response.history,
        }),
      )
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
  }
}

export function postUserCredits(store: any, self$: any) {
  return async (payload: { userId: number; data: any }): Promise<void> => {
    loader$.call.show()
    try {
      await Api.postUserCredits(payload.userId, payload.data)
      const response = await Api.fetchUserCredits(payload.userId)
      await store.dispatch(
        self$.setUserCredits({
          balance: response.balance,
          history: response.history,
        }),
      )
      alert$.call.setNotification('Credit has been successfully applied!')
    } catch (err) {
      alert$.call.setNotification(err)
    }
    loader$.call.hide()
  }
}
