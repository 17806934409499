import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-left: 70px;

  svg {
    height: 20px;
    margin-right: 12px;
    width: 20px;
  }

  ${media.lg} {
  }
`

export const SelectContainer = styled.div`
  width: 120px;

  ${media.lg} {
    min-width: 100px;
  }
`

export const SearchBarInput = styled.input`
  border: 0;
  flex: 1;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  height: 100%;
  outline: none;
  padding: 12px;

  ${media.lg} {
    padding: 20px 20px 20px 12px;
    font-size: 16px;
  }
`
