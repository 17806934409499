import { useState, useEffect } from 'react'
import creditReasons$ from '~/app/store/creditReasons'
import { selectUser } from '~/app/store/app/selectors'
import useForm from '~/app/hooks/useForm'
import { useMappedState } from '~/app/hooks/useReduxStore'
import SaveButton from '~/app/components/Button/SaveButton'
import Button from '~/app/components/Button/Button'
import { ResponsiveRow, CenteredRow, Half } from '~/app/components/FormGrid'
import { FieldSet } from '~/app/components/FieldSet'
import formDefinition from './formDefinition'
import { Wrapper } from './styled'
import alert$ from '~/app/store/alert'

type Props = {
  handleConfirm: (data: any) => void
  handleCancel: () => void
  formData: Record<any, any>
}

const CENTS_MULTIPLIER = 100
const MAX_CREDITS_CENTS = 300 * CENTS_MULTIPLIER

export default function CreditsForm(props: Props) {
  const self = useController(props)
  return (
    <Wrapper>
      <FieldSet legend="Update User Credits" />
      <ResponsiveRow>
        <Half>{self.form.renderField('addOrRemove')}</Half>
        <Half>{self.form.renderField('amount')}</Half>
      </ResponsiveRow>
      <ResponsiveRow>
        <Half>{self.form.renderField('note')}</Half>
        <Half>{self.form.renderField('notify')}</Half>
      </ResponsiveRow>
      <CenteredRow>
        <Half>
          {self.form.renderField('mainCreditsReason', {
            onChange: self.handleMainCreditReasonChange,
            options: self.creditReasons.map(
              (reason: { reasonValue: string; reasonId: number }) => ({
                label: reason.reasonValue,
                value: reason.reasonId,
              }),
            ),
            value: self.form.formState.mainCreditsReason.value,
          })}
        </Half>
        {self.creditSubReasons && (
          <Half>
            {self.form.renderField('subCreditsReason', {
              options: self.creditSubReasons.map((sub: { value: string; id: number }) => ({
                label: sub.value,
                value: sub.id,
              })),
              value: self.form.formState.subCreditsReason.value,
            })}
          </Half>
        )}
      </CenteredRow>
      <ResponsiveRow>
        <Button secondary large onClick={props.handleCancel}>
          Cancel
        </Button>
        <SaveButton
          handleConfirm={self.handleConfirm}
          hasChanges={self.form.hasChanges}
          isValid={self.form.isValid}
        />
      </ResponsiveRow>
    </Wrapper>
  )
}

function useController(props: Props) {
  const [creditSubReasons, setCreditSubReasons] = useState(null)
  const { adminUser, creditReasons, reasonsStatus } = useMappedState((state) => ({
    adminUser: selectUser(state),
    creditReasons: creditReasons$.getReasons(state),
    reasonsStatus: creditReasons$.getStatus(state),
  }))

  useEffect(() => {
    if (reasonsStatus === 'pending') {
      creditReasons$.call.fetchCreditReasons()
    }
  }, [])

  const form = useForm({
    fieldDefinitions: formDefinition,
    formData: props.formData,
  })

  const handleConfirm = () => {
    form.serialize(() => {
      const multiplier = form.formState.addOrRemove.value ? 1 : -1
      const amountCents = Math.round(form.formState.amount.value * CENTS_MULTIPLIER * multiplier)

      if (amountCents > MAX_CREDITS_CENTS) {
        alert$.call.setNotification(
          `Max amount of credits to add is $${MAX_CREDITS_CENTS / CENTS_MULTIPLIER}`,
        )
        return
      }

      props.handleConfirm({
        amount: amountCents,
        authorId: adminUser.id,
        mainReasonId: form.formState.mainCreditsReason.value,
        note: form.formState.note.value,
        notify: form.formState.notify.value,
        subReasonId: form.formState.subCreditsReason.value,
      })
    })
  }

  const handleMainCreditReasonChange = (value: string) => {
    setCreditSubReasons(() => {
      const reason = creditReasons.find(
        (x: { reasonId: number }) => x.reasonId.toString() === value,
      )
      return reason.subReasons
    })
    form.onFieldChange('mainCreditsReason', value)
  }

  return {
    creditReasons,
    creditSubReasons,
    form,
    handleConfirm,
    handleMainCreditReasonChange,
    reasonsStatus,
  }
}
