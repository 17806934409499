import { useState, KeyboardEvent } from 'react'
import TextInput from '../../InputTypes/Text'
import { Tag } from './Tag'
import { Container, TagsContainer, Error } from './styled'

interface Props {
  value: string[]
  onChange: (value: string[]) => void
  disabled?: boolean
}

export default function UrlArrayInput({ value = [], onChange, disabled }: Props) {
  const { inputValue, error, handleKeyDown, handleRemove, handleChange } = useController({
    value,
    onChange,
  })

  return (
    <Container>
      <TextInput
        name="url"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Type URL and press Enter (e.g., /account/*, /add-plates). Leave empty to show on all pages."
        disabled={disabled}
      />
      {error && <Error>{error}</Error>}
      <TagsContainer>
        {value.map((url) => (
          <Tag key={url} onRemove={() => handleRemove(url)} disabled={disabled}>
            {url}
          </Tag>
        ))}
      </TagsContainer>
    </Container>
  )
}

function useController({ value, onChange }: Props) {
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState<string>('')

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return
    e.preventDefault()

    const url = inputValue.trim()

    if (!url) return

    const urlPattern = /^\/[a-z0-9-/*]+$/
    if (!urlPattern.test(url)) {
      setError('Invalid URL format. Use paths starting with "/"')
      return
    }

    const isDuplicate = value.some((existingUrl) => existingUrl.toLowerCase() === url.toLowerCase())

    if (!isDuplicate) {
      onChange([...value, url])
      setInputValue('')
    } else {
      setError('This URL already exists')
    }
  }

  const handleRemove = (urlToRemove: string) => {
    onChange(value.filter((url) => url !== urlToRemove))
  }

  const handleChange = (val: string) => {
    setError('')
    setInputValue(val)
  }

  return { inputValue, error, handleKeyDown, handleRemove, handleChange }
}
