import { ListTable } from '~/app/components/Table'
import DeliveryInfo from './DeliveryInfo'
import Kid from './Kid'
import BasicInformation from './BasicInformation'
import { Title } from '~/app/components/Titles/PageTitle'

type $Props = {
  user: {
    kids: any
  }
  subscriptions: Array<{
    createdAt: string
    kidId: number
  }>
}

export default function UserInfo(props: $Props) {
  return (
    <main>
      <Title marginTop="3rem" center>
        Basic Information
      </Title>
      <ListTable noBorderRadius>
        <BasicInformation
          // @ts-expect-error TODO: Fix error
          user={props.user}
          subscriptions={props.subscriptions}
        />
        <DeliveryInfo user={props.user} />
      </ListTable>
      {props.user.kids.length > 0 && (
        <Title marginTop="4rem" center>
          Kids
        </Title>
      )}
      {props.user.kids.map((kid: any) => (
        <Kid
          key={kid.id}
          kid={kid}
          // @ts-expect-error TODO: Fix error
          userId={props.user.id}
          createdAt={(findKidSubscription(kid.id, props.subscriptions) || {}).createdAt}
        />
      ))}
    </main>
  )
}

const findKidSubscription = (kidId: any, subscriptions: any) => {
  const kidSubscriptions =
    subscriptions.find((subscription: any) => subscription.kidId === kidId) || {}
  return (kidSubscriptions.subscriptions || [])[0]
}
