import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'
import { colors } from '~/app/settings/colors'
const contentBreakPoint = 660

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${media.sm} {
    flex-direction: row;
    padding: 2.5rem 2.1rem;
  }
  align-items: center;
  border-bottom: 0.1rem solid ${colors.lightGray};
`

export const MainInfo = styled.div`
  width: 100%;
  ${media.sm} {
    width: auto;
  }
  padding: 2.5rem 2.1rem;
  ${media.sm} {
    padding: 0;
  }
`
export const Name = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
`
export const BirthDate = styled.h4`
  font-size: 1.5rem;
  font-weight: normal;
  strong {
    text-transform: capitalize;
  }
`
export const Button = styled.button`
  font-size: 1.5rem;
  margin-left: 1rem;
  background: transparent;
  color: ${colors.green};
  border: none;
`
export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  ${media.minW(contentBreakPoint)} {
    flex-direction: row;
  }
`
export const ContentBlock = styled.li`
  display: flex;
  ${media.minW(contentBreakPoint)} {
    width: 50%;
  }
  padding: 3rem 2.6rem;
  &:first-child {
    border-bottom: 0.1rem dashed ${colors.lightGray};
    ${media.minW(contentBreakPoint)} {
      width: calc(50% - 0.1rem);
      border-bottom: none;
      border-right: 0.1rem dashed ${colors.lightGray};
    }
  }
`
