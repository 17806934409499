export default function BannerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#838A9D"
      viewBox="0 0 24 24"
    >
      <title>Promo Banners</title>
      <desc>Promo Banners Icon</desc>
      <path
        fill="#838A9D"
        d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h16v12zM6 10h12v2H6v-2z"
      />
    </svg>
  )
}
