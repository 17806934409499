import styled, { css } from 'styled-components'
import media from '~/app/utils/mediaQueries'
import { switchOn } from '~/app/utils/switchOn'

const paginationRules = css`
  .pagination {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-top: 0;
    padding: 1.6rem 0.8rem 0;
  }

  .page,
  .break {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    height: 24px;
    justify-content: center;
    margin: 0 3px;
    width: 24px;
  }

  .page,
  .previous,
  .next {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    font-weight: 700;
    margin: 0 3px;

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 24px;
      justify-content: center;
      width: 24px;
    }

    &.selected {
      background-color: #4f5b6d;
      color: #fff;
    }
  }

  .previous {
    margin-right: 10px;
  }

  .next {
    margin-left: 10px;
  }

  ${media.lg} {
    .pagination {
      justify-content: flex-end;
    }
  }
`

export const Table = styled.table`
  ${({ theme }) => css`
    display: block;
    border-collapse: separate;
    border-spacing: 0;
    background: ${theme.color.white};
    margin: 0 auto;
    padding: 0;
    width: fit-content;
    max-width: 100%;

    ${media.lg} {
      display: table;
      table-layout: auto;
      max-width: 100%;
      width: 100%;
    }
  `}
`

export const ListTable = styled(Table)<{ noBorderRadius?: boolean; marginTop?: string }>`
  ${paginationRules}
  margin-top: ${(props) => props.marginTop || '0'};
  border-radius: ${(props) => (props.noBorderRadius ? '0' : '0.8rem')};
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
`

export const TableHead = styled.thead`
  ${({ theme }) => css`
    background: ${theme.color.white};
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.08);
    width: 100%;
  `}
`

interface HeaderProps {
  align?: string
  width?: string
  hideMobile?: boolean
  disableBottomPadding?: boolean
}

export const Header = styled.th<HeaderProps>`
  ${({ theme, align, width, hideMobile, disableBottomPadding }) => css`
    padding: 1.2rem 1.6rem ${disableBottomPadding ? '0' : '1.2rem'} 0;
    display: ${hideMobile ? 'none' : 'table-cell'};
    text-align: ${align || 'left'};
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: ${theme.color.gray};
    white-space: nowrap;
    width: ${width || 'auto'};
    position: relative;
    transition: all 0.2s ease;
    background: ${theme.color.white};

    &:first-child {
      padding-left: 1.6rem;
    }

    ${media.lg} {
      display: table-cell;
      font-size: 1.2rem;
      padding: 2.2rem 2.6rem ${disableBottomPadding ? '0' : '2.2rem'} 0;

      &:first-child {
        padding-left: 2.6rem;
      }
    }
  `}
`

export const TableBody = styled.tbody`
  position: relative;
`

export const TableRow = styled.tr<{
  isClickable?: boolean
  skipped?: boolean
  danger?: boolean
  informative?: boolean
}>`
  ${({ theme, isClickable, danger, informative, skipped }) => css`
    border-bottom: 0.1rem solid ${theme.color.lightGray};
    background: ${danger
      ? theme.color.lightRed
      : informative
        ? theme.color.lightBlue
        : theme.color.white};
    transition: all 0.2s ease;
    opacity: ${skipped ? 0.5 : 1};

    ${switchOn(
      isClickable &&
        css`
          cursor: pointer;

          &:hover {
            background: ${danger
              ? theme.color.midRed
              : informative
                ? theme.color.midBlue
                : theme.color.tint2};
            box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.08);
          }

          &:active {
            box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.05);
          }
        `,
    )}

    &:last-child {
      border-bottom: none;
    }
  `}
`

interface CellProps {
  align?: string
  width?: string
  hideMobile?: boolean
  isClickable?: boolean
}

export const Cell = styled.td<CellProps>`
  ${({ theme, align, width, hideMobile, isClickable }) => css`
    display: ${hideMobile ? 'none' : 'table-cell'};
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.5rem 1.5rem 1.5rem 0;
    font-size: 1.3rem;
    color: ${theme.color.darkGray};
    text-align: ${align || 'left'};
    width: ${width || 'auto'};
    min-width: ${width || 'unset'};
    vertical-align: middle;
    white-space: nowrap;
    transition: all 0.2s ease;

    &:first-child {
      padding-left: 1.5rem;
    }

    ${switchOn(
      isClickable &&
        css`
          cursor: pointer;

          &:hover,
          &:active {
            background: ${theme.color.lightGray};
          }
        `,
    )}

    ${media.lg} {
      display: table-cell;
      font-size: 1.4rem;
      padding: 2.2rem 2.6rem 2.2rem 0;
      white-space: normal;

      &:first-child {
        padding-left: 2.6rem;
      }
    }
  `}
`

const getBackgroundColor = (value: any) => {
  switch (value.bgColor) {
    case 'lightRed':
      return value.theme.color.lightRed
    case 'midRed':
      return value.theme.color.midRed
    case 'dashDarkRed':
      return value.theme.color.dashDarkRed
    case 'paleGreen':
      return value.theme.color.paleGreen
    case 'lightGray':
      return value.theme.color.lightGray
    case 'paleGreenDash':
      return value.theme.color.paleGreenDash
    case 'palerGreenDash':
      return value.theme.color.palerGreenDash
    case 'tint':
      return value.theme.color.tint
    case 'gray':
      return value.theme.color.gray
    case 'transparent':
      return 'transparent'
    case 'black':
      return 'black'
    default:
      return value.theme.color.white
  }
}

const getBorderColor = (props: any) => {
  if (props.danger) {
    return props.theme.color.errorRed
  }

  if (props.informative) {
    return props.theme.color.blue
  }

  return 'white'
}

const panelRules = css`
  th,
  td {
    &:first-child {
      padding-left: 30px;
    }
  }

  .cell {
    line-height: 19px;
    padding: 22px 26px 22px 0;
  }
`

export const HeaderFlex = styled.th<{ percent: number }>`
  color: ${(props) => props.theme.color.gray};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  width: ${(props) => props.percent}%;
`

export const OptionCell = styled.td`
  > div {
    display: inline-block;
    width: 22px !important;
    margin: 0 2px;
  }
`

export const LargeCell = Cell.extend`
  font-size: 16px;
  line-height: 22px;
`

const Row = styled.tr`
  background: ${(props) => getBackgroundColor(props)};
  transition: box-shadow 0.25s ease;
`

export const ListRow = Row.extend`
  border-radius: 4px;

  box-shadow: 0;
  cursor: pointer;

  td.last {
    padding-right: 50px;
  }

  &:hover {
    box-shadow: 0 1px 0 ${(props: any) => props.theme.color.green};
  }

  ${(props: any) =>
    props.large &&
    `
    td {
      height: 110px;
    }
  `} ${(props: any) =>
    props.transparent &&
    `
    opacity: .7;
  `};
`

export const BlockRow = Row.extend<{ skipped?: boolean; borderless?: boolean }>`
  box-shadow: inset 0 0 0 1px ${(props: any) => getBorderColor(props)};
  border-radius: 4px;
  position: relative;
  ${(props: any) =>
    props.skipped &&
    `
    opacity: 0.8;
  `} ${(props: any) =>
    props.borderless &&
    css`
      box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
      transition: none;
    `};
`

export const InfoRow = Row.extend`
  td {
    padding: 3px 0 30px 0;
  }
  ${(props: any) =>
    props.centered &&
    `
      td {
        padding: 10px 0 10px 0;
        margin: auto;
      }
  `};
  ${(props: any) =>
    props.bold &&
    `
   font-weight: 1000;
  `};

  ${(props: any) =>
    props.border &&
    `
      border: 2px solid black;
   `};

  ${(props: any) =>
    props.large &&
    `
    th {
      height: 50px;
    }
  `};
  ${(props: any) =>
    props.color &&
    `
   color: ${props.color};
  `};
`

export const BlockTable = Table.extend`
  ${panelRules} border-spacing: 0 20px;
`

export const RowTable = Table.extend`
  ${panelRules} border-spacing: 0 5px;

  th {
    padding: 16px 0;
  }
`
