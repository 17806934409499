const nameSpace = 'app/pages/User/UserOrders'
export const FETCH_ORDERS = `${nameSpace}/FETCH_ORDERS`
export const FETCH_ORDERS_SUCCESS = `${nameSpace}/FETCH_ORDERS_SUCCESS`
export const FETCH_ORDERS_FAIL = `${nameSpace}/FETCH_ORDERS_FAIL`

export const ISSUES_OPTION_IDS_TO_SHOW_PRODUCTS_SELECTOR = [
  5, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
]

export const ISSUES_OPTION_IDS_TO_SHOW_LOT_CODES = [86, 87, 88, 89, 90, 91, 92]
