import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  width: 100%;
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  margin-bottom: 2.4rem;
`
