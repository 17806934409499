import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export const Error = styled.div`
  color: ${({ theme }) => theme.color.errorRed};
`

export const TagContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.lightGray};
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`

export const TagContent = styled.span`
  margin-right: 0.75rem;
`

export const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.paleRed};
  cursor: pointer;
  font-size: 2rem;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
`
