import Api from '~/app/common/api'
import alert$ from '~/app/store/alert'

export function fetchUserProductCoupons(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    try {
      const productCouponsResponse = await Api.fetchUserProductCoupons(userId)
      await store.dispatch(self$.setUserProductCoupons(productCouponsResponse))
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
  }
}
