import moment from 'moment'
import { normalizePhoneNumber } from '~/app/utils/normalizePhoneNumber'
import { Header, LargeCell, TableBody, TableRow } from '~/app/components/Table'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import EditForm from './EditForm'

interface Props {
  user: {
    id: number
    firstName: string
    lastName: string
    email: string
    smsNotification: boolean
    zip: string
    type: string
    kids: any[]
    createdAt: string
    addresses: any
    onboardingZip: any
    ltv: { totalAmountChargedCents: number; weeksWithCharges: number }
  }
  subscriptions?: Array<{
    createdAt: string
    kidId: number
  }>
}

export default function BasicInformation({ user, subscriptions }: Props) {
  const firstName = user.firstName
  const lastName = user.lastName
  const email = user.email
  const smsNotification = user.smsNotification
  const sortedSubscriptions =
    subscriptions?.sort(
      (subscription1, subscription2) =>
        new Date(subscription1.createdAt).getTime() - new Date(subscription2.createdAt).getTime(),
    ) ?? []
  const firstPlanCreatedAt = sortedSubscriptions[0]?.createdAt
  let phone

  if (user.addresses.length) {
    phone = user.addresses[0].phone || ''
  } else {
    phone = ''
  }

  return (
    <TableBody>
      <TableRow />
      <TableRow>
        <Header disableBottomPadding />
        <Header disableBottomPadding>First Name</Header>
        <Header disableBottomPadding>Last Name</Header>
        <Header disableBottomPadding>Email</Header>
        <Header disableBottomPadding>Phone Number</Header>
        <Header disableBottomPadding>SMS Notifications</Header>
        <Header disableBottomPadding />
      </TableRow>
      <TableRow>
        <Header />
        <EditForm type="firstName" formData={{ firstName }} userId={user.id} />
        <EditForm type="lastName" formData={{ lastName }} userId={user.id} />
        <EditForm type="email" formData={{ email }} userId={user.id} />
        <EditForm
          type="phone"
          formData={{ phone }}
          userId={user.id}
          displayMethod={normalizePhoneNumber}
        />{' '}
        <EditForm type="smsNotification" formData={{ smsNotification }} userId={user.id} />
      </TableRow>
      <TableRow>
        <Header disableBottomPadding />
        <Header disableBottomPadding>Onboarding Zip Code</Header>
        <Header disableBottomPadding>Type</Header>
        <Header disableBottomPadding>Number Of Kids</Header>
        <Header disableBottomPadding>First plan created</Header>
        <Header disableBottomPadding>Weeks with orders</Header>
        <Header disableBottomPadding>Total Charged</Header>
      </TableRow>
      <TableRow>
        <LargeCell />
        <LargeCell>{user.onboardingZip || null}</LargeCell>
        <LargeCell>{user.type}</LargeCell>
        <LargeCell>{user.kids.length}</LargeCell>
        <LargeCell>
          {firstPlanCreatedAt ? moment(firstPlanCreatedAt).format('MMM DD YYYY') : '-'}
        </LargeCell>
        <LargeCell>{user.ltv.weeksWithCharges}</LargeCell>
        <LargeCell>${convertCentsToDollars(user.ltv.totalAmountChargedCents)}</LargeCell>
      </TableRow>
    </TableBody>
  )
}
