import React from 'react'
import { TagContainer, TagContent, RemoveButton } from './styled'

interface Props {
  children: React.ReactNode
  onRemove: () => void
  disabled?: boolean
}

export function Tag({ children, onRemove, disabled }: Props) {
  return (
    <TagContainer disabled={disabled}>
      <TagContent>{children}</TagContent>
      {!disabled && <RemoveButton onClick={onRemove}>&times;</RemoveButton>}
    </TagContainer>
  )
}
