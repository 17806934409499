import styled, { css } from 'styled-components'
import Button from '~/app/components/Button/Button'
import media from '~/app/utils/mediaQueries'
import { option, switchOn } from '~/app/utils/switchOn'

export const Wrap = styled.div<{ isOpen: boolean }>`
  background: ${(props) => props.theme.color.dark};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: 12.6rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  transform: translateX(${(props) => (props.isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  width: 22.6rem;

  ${media.lg} {
    z-index: unset;
    position: static;
    transform: none;
  }
`

export const HamburgerButton = styled.button<{ isOpen: boolean }>`
  ${({ isOpen, theme }) => css`
    display: block;
    position: fixed;
    top: 2.5rem;
    left: ${isOpen ? '18.6rem' : '2rem'};
    z-index: 91;
    background: none;
    border: none;
    cursor: pointer;
    padding: 1rem;
    transition: left 0.3s ease-in-out;
    color: ${isOpen ? theme.color.white : theme.color.dark};
    width: 3rem;
    height: 3rem;

    span {
      display: block;
      position: absolute;
      height: 0.2rem;
      width: 2.2rem;
      background: currentColor;
      border-radius: 0.2rem;
      opacity: 1;
      left: 0.4rem;
      transform: rotate(0deg);
      transition:
        transform 0.25s ease-in-out,
        opacity 0.25s ease-in-out;

      &:nth-child(1) {
        top: 8px;
        ${switchOn(
          option(
            isOpen &&
              css`
                top: 1.4rem;
                transform: rotate(135deg);
              `,
          ),
        )}
      }

      &:nth-child(2) {
        top: 1.4rem;
        ${switchOn(
          option(
            isOpen &&
              css`
                opacity: 0;
                transform: translateX(-2rem);
              `,
          ),
        )}
      }

      &:nth-child(3) {
        top: 2rem;
        ${switchOn(
          option(
            isOpen &&
              css`
                top: 1.4rem;
                transform: rotate(-135deg);
              `,
          ),
        )}
      }
    }
  `}

  ${media.lg} {
    display: none;
  }
`

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 89;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;

  ${media.lg} {
    display: none;
  }
`

export const Bottom = styled.div`
  margin: auto 0 0 0;
  padding: 0 0 56px 0;
  text-align: center;
`

export const LogoutButton = styled(Button)`
  width: 100%;
`
