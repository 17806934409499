import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 10px;
`

export const ResponsiveRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.lg} {
    justify-content: space-between;
    flex: 1;
    margin-bottom: 10px;
    gap: 0;
    flex-direction: row;
  }
`

// @ts-ignore
export const CenteredRow = Row.extend`
  display: flex;
  justify-content: center;
`

export const Full = styled.div`
  width: 100%;
`

export const Half = styled.div`
  width: 100%;

  ${media.lg} {
    width: calc(50% - 10px);
  }
`

export const OneThird = styled.div`
  width: calc(35% - 10px);
`
export const TwoThird = styled.div`
  width: calc(65% - 10px);
`
export const OneFourth = styled.div`
  width: calc(25% - 10px);
`

export const ThreeFourth = styled.div`
  width: calc(75% - 10px);
`

export const OneFifth = styled.div`
  width: calc(35% - 10px);

  ${media.lg} {
    width: calc(20% - 10px);
  }
`
