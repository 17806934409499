import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'
export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
  padding: 20px 22px;

  ${media.lg} {
    padding: 40px 44px;
  }
`

export const Question = styled.h2`
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 25px auto 35px;
`

export const Options = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  ${media.lg} {
    flex-direction: row;
  }
`
export const Option = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lg} {
    max-width: 50%;
  }
`
export const Date = styled.time`
  display: block;
  margin-bottom: 20px;
`
