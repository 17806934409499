import { Cell, TableRow } from '~/app/components/Table'
import formatProductType from '~/app/utils/formatProductType'
import { Product, Products, Skipped, IconContainer } from './styled'
import { validAddOnProductTypesMap, validAnchorProductTypesMap } from '~/app/common/types'
import TrackingIcon from '~/app/assets/svg/TrackingIcon'
import Options from './Options'

interface Props {
  active: boolean
  addOns: {
    active: boolean
    id: number
    products: {
      count: number
      id: number
      inStock: boolean
      name: string
      packetCount: number
    }[]
    skipped: boolean
    type: validAddOnProductTypesMap
    skippable: boolean
  }[]
  allowForcedUnSkip: boolean
  chargeId: number
  chargedAt: string
  data: any
  deliveryIssues: any
  deliveryDate: {
    format: (value: string) => Date
  }
  editable: boolean
  editableTo: string
  groupIndex: number // eslint-disable-line
  hasBeenSentToShipStation: boolean
  id: number
  index: number
  isFirst?: boolean
  kidId: number
  kidName: string
  showKidName: boolean
  orderId: number
  products: { name: string; count: number; inStock: boolean }[]
  skippable: boolean
  skipped: boolean
  status: string
  type: validAnchorProductTypesMap
  userId: number
  trackingUrl: string | null
}

export default function Order(props: Props) {
  const showOutOfStockStatus = props.status === 'pending'
  const { trackingUrl } = props

  return (
    <TableRow skipped={props.skipped} danger={props.deliveryIssues?.length}>
      <Cell>
        <strong>
          {props.showKidName ? `${props.kidName}'s ` : ''}
          {formatProductType(props.type)}
        </strong>
      </Cell>
      <Cell>{props.orderId}</Cell>
      <Cell>
        {trackingUrl && <TrackOrderLinkedIcon trackingUrl={trackingUrl} />}
        {props.status}
      </Cell>
      <Cell>
        <Products>
          {props.products.map(({ name, count, inStock }) => (
            // @ts-expect-error: TODO: Fix type error
            <Product key={name} inStock={showOutOfStockStatus ? inStock : true}>
              <strong>{count}x</strong> {name}
            </Product>
          ))}
        </Products>
      </Cell>
      <Cell>
        <Products>
          {props.addOns.map(({ type, products, active, skipped }) =>
            !skipped ? (
              products.map(({ name, count, inStock, packetCount }) => {
                if (!active) {
                  return null
                }
                return (
                  // @ts-expect-error: TODO: Fix type error
                  <Product key={name} inStock={showOutOfStockStatus ? inStock : true}>
                    <strong>{count * (packetCount ?? 1)}x</strong> {name}
                  </Product>
                )
              })
            ) : (
              <Skipped key={type}>Skipped {formatProductType(type)}</Skipped>
            ),
          )}
        </Products>
      </Cell>
      <Cell>
        <Options
          active={props.active}
          addOns={props.addOns}
          data={props.data}
          editable={props.editable}
          editableTo={props.editableTo}
          groupIndex={props.groupIndex}
          hasBeenSentToShipStation={props.hasBeenSentToShipStation}
          id={props.id}
          index={props.index}
          isFirst={props.isFirst}
          skipped={props.skipped}
          kidId={props.kidId}
          orderId={props.orderId}
          skippable={props.skippable}
          userId={props.userId}
          chargedAt={props.chargedAt}
          chargeId={props.chargeId}
          type={props.type}
        />
      </Cell>
    </TableRow>
  )
}

interface LinkedIconProps {
  trackingUrl: string
}

function TrackOrderLinkedIcon(props: LinkedIconProps) {
  return (
    <IconContainer>
      <a href={props.trackingUrl} target="_blank" rel="noopener noreferrer">
        <TrackingIcon />
      </a>
    </IconContainer>
  )
}
