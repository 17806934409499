import { Header, LargeCell, ListTable, TableBody, TableRow } from '~/app/components/Table'
import { MISSING_INFO_TEXT, getGender, getEatsMeat } from '~/app/utils/formatStringsforFE'
import {
  getAge,
  getChildPhase,
  getFoodOrigin,
  getEatingStyle,
  getAllergies,
  getAgeAtSignup,
  getBirthdate,
} from './dataHelpers'
import EditForm from './EditForm'

interface Props {
  kid: {
    id: number
    name: string
    gender: 'male' | 'female'
    eatingStyle: 0 | 1 | 2 | 3
    allergy: 'severe' | 'mild' | 'none'
    birthdate: string
    phase: 'both' | 'finger' | 'pureed' | 'notSolids'
    foodOrigin: 'both' | 'store' | 'homemade'
    eatsMeat: boolean
  }
  userId: number
  createdAt: string
}

const defaultBirthdate = '01/01/2020'

export default function Kid({ kid, userId, createdAt }: Props) {
  return (
    <ListTable noBorderRadius>
      <TableBody>
        <TableRow />
        <TableRow>
          <Header disableBottomPadding />
          <Header disableBottomPadding>First Name</Header>
          <Header disableBottomPadding>Gender</Header>
          <Header disableBottomPadding>BirthDate</Header>
          <Header disableBottomPadding>Age</Header>
          <Header disableBottomPadding />
          <Header disableBottomPadding />
        </TableRow>
        <TableRow>
          <LargeCell />
          <EditForm initialValues={{ name: kid.name }} type={'name'} kid={kid} userId={userId} />
          <LargeCell>{getGender(kid.gender)}</LargeCell>
          <EditForm
            initialValues={{
              birthdate: kid.birthdate ? getBirthdate(kid.birthdate) : defaultBirthdate,
            }}
            type="birthdate"
            kid={kid}
            userId={userId}
          />
          <LargeCell>{kid.birthdate ? getAge(kid.birthdate) : MISSING_INFO_TEXT}</LargeCell>
          <LargeCell>
            {kid.birthdate ? getAgeAtSignup(kid.birthdate, createdAt) : MISSING_INFO_TEXT}
          </LargeCell>
          <LargeCell />
        </TableRow>
      </TableBody>
      <TableBody>
        <TableRow />
        <TableRow>
          <Header disableBottomPadding />
          <Header colSpan={1} disableBottomPadding></Header>
          <Header colSpan={2} disableBottomPadding>
            Eating Habits
          </Header>
          <Header colSpan={3} disableBottomPadding>
            Mainly eats
          </Header>
          <Header disableBottomPadding />
        </TableRow>
        <TableRow>
          <LargeCell />
          <LargeCell></LargeCell>
          <LargeCell colSpan={2}>{getChildPhase(kid.phase) || MISSING_INFO_TEXT}</LargeCell>
          <LargeCell colSpan={3}>
            {kid.foodOrigin ? getFoodOrigin(kid.foodOrigin) : MISSING_INFO_TEXT}
          </LargeCell>
          <LargeCell />
        </TableRow>
        <TableRow>
          <Header disableBottomPadding />
          <Header colSpan={1} disableBottomPadding>
            Eating Style
          </Header>
          <Header colSpan={2} disableBottomPadding>
            Allergies
          </Header>
          <Header colSpan={3} disableBottomPadding>
            Meat
          </Header>
          <Header disableBottomPadding />
        </TableRow>
        <TableRow>
          <LargeCell />
          <LargeCell colSpan={1}>{getEatingStyle(kid.eatingStyle) || MISSING_INFO_TEXT}</LargeCell>
          <LargeCell colSpan={2}>
            {kid.allergy ? getAllergies(kid.allergy) : MISSING_INFO_TEXT}
          </LargeCell>
          <LargeCell colSpan={3}>{getEatsMeat(kid.eatsMeat)}</LargeCell>
          <LargeCell />
        </TableRow>
      </TableBody>
    </ListTable>
  )
}
