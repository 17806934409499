import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Block = styled.div`
  padding: ${(props: any) => props.paddingTop || 16}px 16px
    ${(props: any) => props.paddingBottom || 16}px 16px;
  background: ${(props) => props.theme.color.white};
  border-radius: 4px;
  margin-bottom: 20px;

  ${media.lg} {
    padding: ${(props: any) => props.paddingTop || 50}px 50px
      ${(props: any) => props.paddingBottom || 50}px 50px;
    margin-bottom: 40px;
  }
`

// @ts-ignore
export const GridBlockOneThird = Block.extend`
  display: inline-block;
  margin-bottom: 0;
  margin-right: 40px;
  padding: 28px 44px;
  width: calc(100% / 3 - 40px);
`
