import { Block } from '~/app/components/Block'
import Page from '~/app/components/Page'
import { Title } from '~/app/components/Titles/PageTitle'
import { productTypeNamePlural } from '~/app/common/dataMaps/productTypeName'
import { type Link as LinkType, ProductType } from '~/app/common/types'
import { LinkList, StyledLink } from './styled'

export default function ProductDefaults() {
  const self = useController()

  return (
    <Page>
      <Title>Manage Product Defaults</Title>
      <Block>
        <LinkList>
          {self.productDefaultsLinks.map(({ name, path }) => (
            // @ts-expect-error
            <StyledLink key={path} to={path}>
              {name}
            </StyledLink>
          ))}
        </LinkList>
      </Block>
    </Page>
  )
}

function useController() {
  const productDefaultsLinks: LinkType[] = [
    ProductType.plate,
    ProductType.biteable,
    ProductType.luncher,
    ProductType.smoothie,
    ProductType.snack,
    ProductType.puff,
  ].map((productType) => ({
    name: productTypeNamePlural[productType],
    path: `/product-defaults/${productType}`,
  }))

  return {
    productDefaultsLinks,
  }
}
