import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const SpacerCol = styled.td`
  width: 21px;
`
export const ProductsCounts = styled.ul`
  padding: 0;
  display: flex;
`
export const ProductsCount = styled.li`
  margin-right: 1.4rem;
  margin-bottom: 2.5rem;
  list-style-type: none;
  ${media.md} {
    margin-bottom: 0;
  }
`
export const Count = styled.span`
  margin-right: 0.8rem;
  font-size: 1.4rem;
  font-weight: 800;
`
export const IconWrapper = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: 1px solid ${(props) => props.theme.color.green};
  border-radius: 5px;
  color: ${(props) => props.theme.color.green};
  width: 180px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
  outline: none;
  path,
  circle {
    fill: currentColor;
  }

  svg {
    margin-left: 0;
    min-height: 30px;
    max-height: 30px;
    fill: currentColor;
  }

  ${media.md} {
    svg {
      margin-right: auto;
      margin-left: auto;
    }
    width: auto;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    border: 0;
    color: ${(props) => (props.isRed ? props.theme.color.red : props.theme.color.darkGray)};
    ${(props) =>
      props.isTrack &&
      `
      a, a:visited {
        color: ${props.theme.color.green};
        text-decoration: none;
      }
    `}
    margin: 0 auto;
  }
  ${(props) =>
    props.showDangerMode &&
    `
    color: #ff4c4c;
  `};
`
