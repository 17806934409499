import { useMappedState } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'
import SaveButton from '~/app/components/Button/SaveButton'
import useForm from '~/app/hooks/useForm'
import Button from '~/app/components/Button/Button'
import { ResponsiveRow, Half } from '~/app/components/FormGrid'
import { FieldSet } from '~/app/components/FieldSet'
import { Wrapper } from './styled'
import formDefinition from './formDefinition'

type Props = {
  handleConfirm: (data: any) => void
  handleCancel: () => void
  formData: Record<any, any>
  isNew: boolean
}

function CouponsForm(props: Props) {
  const self = useController(props)
  return (
    <Wrapper>
      <FieldSet legend="Add User Coupon" />
      <ResponsiveRow>
        <Half>{self.form.renderField('code')}</Half>
        <Half>
          {self.form.renderField('plan', {
            options: self.options,
          })}
        </Half>
      </ResponsiveRow>
      <ResponsiveRow>
        <Button secondary large onClick={props.handleCancel}>
          Cancel
        </Button>
        <SaveButton
          isNew={self.isNew}
          handleConfirm={self.handleConfirm}
          hasChanges={self.form.hasChanges}
          isValid={self.form.isValid}
        />
      </ResponsiveRow>
    </Wrapper>
  )
}

function useController(props: Props) {
  const { subscriptions } = useMappedState((state) => ({
    subscriptions: user$.getUserSubscriptions(state) as Array<any>,
  }))

  const options = subscriptions.map((item) => ({
    label: `${capitalizeFirstCharacter(item.type)} - ${item.templateId}`,
    value: item.templateId,
  }))

  const form = useForm({
    fieldDefinitions: formDefinition,
    formData: props.formData,
  })

  const handleConfirm = () => {
    form.serialize(() => {
      props.handleConfirm({
        code: form.formState.code.value,
        templateId: form.formState.plan.value,
      })
    })
  }

  return {
    form,
    handleConfirm,
    options,
    isNew: props.isNew || false,
  }
}

function capitalizeFirstCharacter(message: string) {
  return message.charAt(0).toUpperCase() + message.slice(1)
}

export default CouponsForm
