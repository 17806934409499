import React from 'react'
import { getPromoBanners } from '~/app/store/promoBanners/selectors'
import promoBanners$ from '~/app/store/promoBanners'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { ListTable, Header, TableHead, TableBody, TableRow } from '~/app/components/Table'
import ConfirmationModal from '~/app/components/Modal/ConfirmationModal'
import Item from './Item'

type $Props = {
  history: {
    push: (value: string) => void
  }
}

export default function List(props: $Props) {
  const self = useController()
  return (
    <>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header width="8rem">Order</Header>
            <Header>Title</Header>
            <Header>Start Date</Header>
            <Header>End Date</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {self?.promoBanners?.length &&
            self.promoBanners.map((promoBanner: any) => (
              <Item
                key={promoBanner.id}
                {...promoBanner}
                onClick={() => props.history.push(`promo-banners/${promoBanner.id}`)}
                onDelete={self.handleShowConfirmationModal}
              />
            ))}
        </TableBody>
      </ListTable>
      {self.promoBannerToDelete && (
        <ConfirmationModal
          isOpen={self.showConfirmationModal}
          handleCancel={self.handleCancelConfirmationModal}
          handleConfirm={self.handleConfirmConfirmationModal}
          title="Delete Promo Banner"
          text={`Are you sure that you want to delete promo banner #${self.promoBannerToDelete}?`}
          confirmText="Yes, delete it"
          cancelText="No, cancel"
        />
      )}
    </>
  )
}

function useController() {
  const [showConfirmationModal, setConfirmationModalVisibility] = React.useState(false)
  const [promoBannerToDelete, setPromoBannerToDelete] = React.useState(null)
  const { promoBanners } = useDataloader()

  const handleShowConfirmationModal = (id: any) => {
    setPromoBannerToDelete(id)
    setConfirmationModalVisibility(() => true)
  }

  const handleConfirmConfirmationModal = () => {
    promoBanners$.call.deletePromoBanner(promoBannerToDelete)
    setPromoBannerToDelete(null)
    setConfirmationModalVisibility(() => false)
  }

  const handleCancelConfirmationModal = () => {
    setConfirmationModalVisibility(() => false)
  }

  return {
    promoBannerToDelete,
    promoBanners,
    handleCancelConfirmationModal,
    handleConfirmConfirmationModal,
    handleShowConfirmationModal,
    setConfirmationModalVisibility,
    setPromoBannerToDelete,
    showConfirmationModal,
  }
}

function useDataloader() {
  const { promoBanners } = useMappedState((state) => {
    return {
      promoBanners: getPromoBanners(state).data,
    }
  })

  React.useEffect(() => {
    promoBanners$.call.fetchPromoBanners()
  }, [])

  return {
    promoBanners,
  }
}
