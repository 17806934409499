import LightBox from '~/app/components/LightBox'
import { Wrapper, DialogHeader, Heading, Content, LoaderWrapper, TableWrapper } from './styled'
import moment from 'moment'
import { Order } from '../types'
import user$ from '~/app/store/user'
import { useEffect } from 'react'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { ApiOrderRefund } from '~/app/common/api/types'
import RefundOrderForm from './Form'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { OrderRefundsStatus, RootState } from '~/app/store/user/orderRefunds/types'
import { option, switchOn } from '~/app/utils/switchOn'
import Loader from '~/app/components/Loader'
import { Cell, Header, ListTable, TableBody, TableHead, TableRow } from '~/app/components/Table'

interface Props {
  onClose: () => void
  order: Order
}

export default function RefundOrderDialog(props: Props) {
  const self = useController(props)

  return (
    <LightBox isOpen closeHandler={self.handleClose}>
      <Wrapper>
        <Content>
          {switchOn(
            option(
              self.isLoading && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ),
            ),
            option(
              self.orderRefunds.length > 0 && (
                <>
                  <DialogHeader>
                    <Heading noTopMargin>Previous refunds for this charge</Heading>
                    <span>
                      Note: It shows all refunds associated with this charge, not this order, so you
                      might see refunds from different order here.
                    </span>
                  </DialogHeader>
                  <TableWrapper>
                    <ListTable>
                      <TableHead>
                        <TableRow>
                          <Header>Order ID</Header>
                          <Header>Amount</Header>
                          <Header>Date</Header>
                          <Header>Products</Header>
                          <Header>Author</Header>
                          <Header>Custom Reason</Header>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {self.orderRefunds.map((refund: ApiOrderRefund) => (
                          <TableRow key={String(refund.createdAt)}>
                            <Cell>{refund.metadata?.orderId}</Cell>
                            <Cell>${convertCentsToDollars(refund.amount)}</Cell>
                            <Cell>{moment(refund.createdAt).format('MM/DD/YYYY')}</Cell>
                            <Cell>
                              {refund.products?.map((product: { id: number; count: number }) => (
                                <div key={product.id}>
                                  {self.productsByIdOrSku[product.id]?.name ?? product.id} x{' '}
                                  {product.count}
                                </div>
                              ))}
                            </Cell>
                            <Cell>{refund.user?.email ?? 'N/A'}</Cell>
                            <Cell>{refund.customRefundReason}</Cell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </ListTable>
                  </TableWrapper>
                </>
              ),
            ),
          )}
          {self.showRefundForm && !self.isLoading && (
            <>
              <DialogHeader>
                <Heading noTopMargin>Refund Order</Heading>
              </DialogHeader>
              <RefundOrderForm
                order={props.order}
                onSubmit={self.handleCreateOrderRefund}
                isLoading={self.isLoading}
              />
            </>
          )}
        </Content>
      </Wrapper>
    </LightBox>
  )
}

function useController(props: Props) {
  const { order } = props
  const orderRefundsState = useMappedState(
    (state: object & { user: RootState }) => state.user.orderRefunds as RootState['orderRefunds'],
  )
  const isLoading = orderRefundsState.status !== OrderRefundsStatus.loaded
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const showRefundForm = moment(props.order.editableTo).isAfter(moment().subtract(3, 'months'))

  useEffect(() => {
    user$.call.fetchOrderRefunds(order.id)
  }, [order.id])

  const handleClose = () => {
    user$.call.setOrderRefundsInitialState()
    props.onClose()
  }

  const handleCreateOrderRefund = (payload: {
    products: { id: number; count: number }[]
    customRefundReason?: string
    customRefundAmount?: number
  }) => {
    user$.call.createOrderRefund({
      products: payload.products,
      orderId: order.id,
      chargeId: order.charge,
      customRefundReason: payload.customRefundReason,
      customRefundAmount: payload.customRefundAmount,
    })
  }

  return {
    orderRefunds: orderRefundsState.data,
    handleClose,
    handleCreateOrderRefund,
    productsByIdOrSku,
    isLoading,
    showRefundForm,
  }
}
