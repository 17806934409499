import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-left: 70px;

  svg {
    height: 20px;
    width: 20px;
  }
`

export const SearchBarInput = styled.input`
  border: 0;
  flex: 1;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: 100%;
  outline: none;
  padding: 12px;

  ${media.lg} {
    font-size: 16px;
    padding: 20px 20px 20px 12px;
  }
`
