import { StyledInput } from './styled'

interface Props {
  onChange: (event: any) => void
  onKeyDown?: (event: any) => void
  inputType: string
  label?: string
  placeholder?: string
  disabled?: boolean
  small?: boolean
  value: string | number
  name: string
  'data-cy'?: string
}

export default function Text({
  inputType,
  value,
  label,
  placeholder,
  onChange,
  small,
  disabled,
  name,
  'data-cy': dataCy,
  onKeyDown,
}: Props) {
  return (
    <StyledInput
      type={inputType}
      value={value}
      aria-label={label}
      placeholder={placeholder}
      onChange={(event: any) => onChange(event.target.value)}
      // @ts-ignore
      small={small}
      disabled={disabled}
      name={name}
      role="textbox"
      data-cy={dataCy}
      onKeyDown={onKeyDown}
    />
  )
}

Text.defaultProps = {
  inputType: 'text',
  label: '',
  small: false,
  value: '',
}
