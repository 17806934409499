import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const HeaderSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
  top: -30px;
  width: 100%;

  ${media.lg} {
    top: -50px;
  }
`

export const ProfileImage = styled.img`
  border-radius: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 80px;
  margin: 0 auto;
  width: 80px;

  ${media.lg} {
    height: 100px;
    width: 100px;
  }
`

export const Title = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 20px;
  line-height: 1.3;
  margin: 12px 0;
  text-align: center;

  ${media.lg} {
    font-size: 24px;
    margin: 16px 0;
  }
`

export const UserInfo = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 8px 0;
  padding: 0;
  gap: 10px;

  li {
    color: ${(props) => props.theme.color.darkGray};
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: 0;
    text-transform: uppercase;
  }

  ${media.lg} {
    gap: 15px;
  }
`

export const AddTagButton = styled.button`
  border: 1px solid ${(props) => props.theme.color.gray};
  border-radius: 100px;
  box-sizing: border-box;
  color: ${(props) => props.theme.color.gray};
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-left: 8px;
  padding: 8px 12px; // Increased touch target
  text-transform: uppercase;
  transition: all 0.2s ease;

  svg {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.gray};
    border: 1px solid ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.white};
    transform: translateY(-1px);

    svg {
      fill: white !important;
    }
  }
`
export const ImageWrapper = styled.span`
  padding-right: 5px;
`
export const MagicWandSection = styled.div`
  margin-bottom: 10px;
  svg {
    margin-right: 8px;
    margin-left: 8px;
  }
`

export const MagicLinkSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  ${media.lg} {
    max-width: 500px;
    padding: 0;
  }
`

export const MagicLinkNotification = styled.div`
  margin: 10px 0;
  flex-direction: column;
  color: ${(props) => (props.danger ? props.theme.color.red : props.theme.color.green)};
  text-align: center;
  padding: 8px;
`

export const LinkButton = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.color.green};
  padding: 8px; // Increased touch target
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const TagsLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
