import PropTypes from 'prop-types'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import moment from 'moment'
import { connect } from 'react-redux'
import user$ from '~/app/store/user'
import {
  BlockRow,
  Cell,
  ListTable,
  TableHead,
  TableRow,
  TableBody,
  Header,
} from '~/app/components/Table'
import AddElementButton from '~/app/components/Button/AddElementButton'
import { Wrapper, Balance, ButtonWrapper } from './styled'
import CreditsForm from './CreditsForm'
import CouponsForm from './CouponsForm'
import { Title } from '~/app/components/Titles/PageTitle'

const boolMap = (val) => (val ? 'Yes' : 'No')

const formatCurrency = (money) => (parseFloat(money) / 100).toFixed(2)

function UserCredits({
  balance,
  history,
  showAddCredits,
  handleAddCreditsSubmit,
  handleAddCreditsClick,
  handleCreditsCancel,
  handleAddCouponsSubmit,
  handleAddCouponsClick,
  handleCouponsCancel,
  showAddCoupons,
  userCoupons,
  userProductCoupons,
}) {
  return (
    <Wrapper>
      <Title marginTop="3rem" center>
        Credits
      </Title>
      <Balance>Current Balance: ${formatCurrency(balance)} </Balance>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header>Created At</Header>
            <Header>Amount</Header>
            <Header>Note</Header>
            <Header>Reason</Header>
            <Header>Sub reason</Header>
            <Header>Created By</Header>
            <Header>Coupon Code</Header>
            <Header>Is referral?</Header>
            <Header>Used?</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((credit, i) => (
            <BlockRow key={i}>
              <Cell>{moment(credit.createdAt).format('MM-DD-YYYY')}</Cell>
              <Cell>${formatCurrency(credit.amount)}</Cell>
              <Cell>{credit.note ? credit.note : 'None'}</Cell>
              <Cell>{credit.mainCreditReason}</Cell>
              <Cell>{credit.subCreditReason}</Cell>
              <Cell>{credit.author ? credit.author.firstName : 'None'}</Cell>
              <Cell>{credit.coupon ? credit.coupon.code : null}</Cell>
              <Cell>{credit.coupon ? boolMap(credit.coupon.referral) : null}</Cell>
              <Cell>{boolMap(credit.used)}</Cell>
            </BlockRow>
          ))}
        </TableBody>
      </ListTable>
      {showAddCredits ? (
        <CreditsForm
          formData={{}}
          handleConfirm={handleAddCreditsSubmit}
          handleCancel={handleCreditsCancel}
        />
      ) : (
        <ButtonWrapper>
          <AddElementButton text="Update Credits" handleClick={handleAddCreditsClick} />
        </ButtonWrapper>
      )}
      <Title>Unused Coupons</Title>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header>Date Added</Header>
            <Header>Code</Header>
            <Header>Scope</Header>
            <Header>Plan Id</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {userCoupons.map((userCoupon, i) => (
            <BlockRow key={i}>
              <Cell>{moment(userCoupon.createdAt).format('MM-DD-YYYY')}</Cell>
              <Cell>{userCoupon.coupon.code}</Cell>
              <Cell>{userCoupon.templateId ? 'Plan' : 'User'}</Cell>
              <Cell>{userCoupon.templateId}</Cell>
            </BlockRow>
          ))}
        </TableBody>
      </ListTable>
      {showAddCoupons ? (
        <CouponsForm
          formData={{}}
          handleConfirm={handleAddCouponsSubmit}
          handleCancel={handleCouponsCancel}
        />
      ) : (
        <ButtonWrapper>
          <AddElementButton text="Add Coupon" handleClick={handleAddCouponsClick} />
        </ButtonWrapper>
      )}
      <Title>Free Product Codes</Title>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header>Date Added</Header>
            <Header>Code</Header>
            <Header>Products</Header>
            <Header>Used?</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {userProductCoupons.map((productCoupon, i) => (
            <BlockRow key={i}>
              <Cell>{moment(productCoupon.createdAt).format('MM-DD-YYYY')}</Cell>
              <Cell>{productCoupon.coupon.code}</Cell>
              <Cell>{productCoupon.coupon.products.map(({ sku }) => sku).join(', ')}</Cell>
              <Cell>{`${!productCoupon.productsAvailable}`.toUpperCase()}</Cell>
            </BlockRow>
          ))}
        </TableBody>
      </ListTable>
    </Wrapper>
  )
}

UserCredits.propTypes = {
  balance: PropTypes.number,
  handleAddCouponsClick: PropTypes.func.isRequired,
  handleAddCouponsSubmit: PropTypes.func.isRequired,
  handleAddCreditsClick: PropTypes.func.isRequired,
  handleAddCreditsSubmit: PropTypes.func.isRequired,
  handleCouponsCancel: PropTypes.func.isRequired,
  handleCreditsCancel: PropTypes.func.isRequired,
  history: PropTypes.array,
  showAddCoupons: PropTypes.bool.isRequired,
  showAddCredits: PropTypes.bool.isRequired,
  userCoupons: PropTypes.array,
  userProductCoupons: PropTypes.array,
}

const mapStateToProps = (state) => ({
  balance: user$.getUserCredits(state).balance,
  history: user$.getUserCredits(state).history,
  userId: user$.getDetails(state).id,
  userCoupons: user$.getUserCoupons(state),
  userProductCoupons: user$.getUserProductCoupons(state),
})

export default compose(
  connect(mapStateToProps, null),
  withState('showAddCredits', 'setShowCreditsVisibility', false),
  withState('showAddCoupons', 'setShowCouponsVisibility', false),
  lifecycle({
    // TODO: redo with useState
    componentDidMount() {
      const userId = this.props.userId || window.location.pathname.split('/')[2]
      user$.call.fetchUserCredits(userId)
      user$.call.fetchUserCoupons(userId)
      user$.call.fetchUserProductCoupons(userId)
    },
  }),
  withHandlers({
    handleAddCouponsClick:
      ({ showAddCoupons, setShowCouponsVisibility }) =>
      () => {
        setShowCouponsVisibility(() => !showAddCoupons)
      },
    handleAddCouponsSubmit:
      ({ setShowCouponsVisibility, userId }) =>
      async (data) => {
        user$.call.postUserCoupon({
          code: data.code,
          templateId: data.templateId ? parseInt(data.templateId) : undefined,
          userId,
        })
        setShowCouponsVisibility(() => false)
      },
    handleAddCreditsClick:
      ({ showAddCredits, setShowCreditsVisibility }) =>
      () => {
        setShowCreditsVisibility(() => !showAddCredits)
      },
    handleAddCreditsSubmit:
      ({ userId, setShowCreditsVisibility }) =>
      (data) => {
        user$.call.postUserCredits({
          data,
          userId,
        })
        setShowCreditsVisibility(() => false)
      },
    handleCouponsCancel:
      ({ setShowCouponsVisibility }) =>
      () => {
        setShowCouponsVisibility(() => false)
      },
    handleCreditsCancel:
      ({ setShowCreditsVisibility }) =>
      () => {
        setShowCreditsVisibility(() => false)
      },
  }),
)(UserCredits)
