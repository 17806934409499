import moment from 'moment'
import { uniqBy } from 'lodash'
import { NodeEnv, UserRole } from '~/app/common/constants'
import { Header, HeaderFlex, ListTable, TableHead, TableRow } from '~/app/components/Table'
import useLoader from '~/app/hooks/useLoader'
import useHasUserRole from '~/app/hooks/useHasUserRole'
import Order from './Order'
import useDataLoader from './useDataLoader'
import {
  Wrapper,
  TableBody,
  OrderGroupDate,
  OrderGroups,
  OrderGroup,
  Notice,
  OrderGroupHeader,
  OrderGroupActions,
  OrderGroupTextColumn,
} from './styled'
import { useFeatureFlagEnableCadenceSync } from '~/app/hooks/useFeatureFlag'
import useIsEnvironment from '~/app/hooks/useIsEnvironment'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import ActionsForm from './ActionsForm'

type Carrier = 'ups' | 'fedex' | 'ontrac'
type Region = 'east' | 'west'

interface Props {
  user: {
    addresses: {
      address: string
      apt: string
      city: string
      firstName: string
      id: number
      lastName: string
      note?: string
      phone: string
      shippingInfo: {
        warehouseId: number
        region: Region
        carrier: Carrier
        transitDays: number
        service: string
      }
      state: string
      type: string
      zip: string
    }[]
    carrier?: string
    createdAt: string
    deletedAt?: string
    email: string
    firstName: string
    id: number
    kids: {
      allergy?: string
      birthdate: string
      eatingStyle?: string
      eatsMeat: boolean
      foodOrigin?: string
      gender?: string
      id: number
      name: string
      phase?: string
    }[]
    lastName?: string
    onboardingZip: string
    service?: string
    shippingInfo: {
      carrier: Carrier
      code: string
      region: Region
      service: string
      state: string
      transitDays: number
      warehouseId: number
    }
    smsNotifications: boolean
    transitDays?: number
    type: string
    updatedAt: string
    warehouseId?: number
  }
  pausedSubscriptions: boolean
}

export default function DeliverySchedule(props: Props) {
  const {
    shouldShowActionsForm,
    deactivatedAccount,
    data,
    loader,
    isSuperAdmin,
    giftProductsLoading,
    hasMultipleKidsOrders,
    upcomingWeekTotal,
    secondUpcomingWeekTotal,
    areAllOrdersFromFirstWeekCharged,
  } = useController(props)

  if (giftProductsLoading) {
    return loader
  }

  return (
    <Wrapper>
      {data.groupedOrders.length ? (
        <OrderGroups>
          {data.groupedOrders.map((orderGroup, i) => {
            const showKidName = hasMultipleKidsOrders(orderGroup.orders)
            const orderGroupDate = moment(orderGroup.date).format('dddd, MMMM DD, YYYY')

            return (
              <OrderGroup key={i.toString()}>
                <OrderGroupHeader>
                  <OrderGroupTextColumn>
                    <OrderGroupDate>{orderGroupDate}</OrderGroupDate>
                    {i === 0 && upcomingWeekTotal && (
                      <span>Total cost for the week: ${upcomingWeekTotal}</span>
                    )}
                    {i === 1 && areAllOrdersFromFirstWeekCharged && secondUpcomingWeekTotal && (
                      <span>Total cost for the week: ${secondUpcomingWeekTotal}</span>
                    )}
                  </OrderGroupTextColumn>
                  {shouldShowActionsForm && (
                    <OrderGroupActions>
                      <ActionsForm
                        userId={props.user.id}
                        deliveryEstimate={orderGroupDate}
                        orders={orderGroup.orders}
                        groupedOrders={data.groupedOrders}
                        groupIndex={i}
                      />
                    </OrderGroupActions>
                  )}
                </OrderGroupHeader>
                <ListTable>
                  <TableHead>
                    <TableRow>
                      <Header>Type</Header>
                      <Header>Order id</Header>
                      <HeaderFlex percent={8}>Status</HeaderFlex>
                      <Header>Products</Header>
                      <Header>Add-Ons</Header>
                      <HeaderFlex percent={10}>Actions</HeaderFlex>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    // @ts-expect-error TODO: Fix error
                    inactive={props.pausedSubscriptions || deactivatedAccount}
                  >
                    {orderGroup.orders.map((order: any, j: number) => (
                      <Order
                        key={j.toString()}
                        index={j}
                        groupIndex={i}
                        kidId={orderGroup.kidId}
                        kidName={order.kidsName}
                        showKidName={showKidName}
                        allowForcedUnSkip={isSuperAdmin}
                        {...order}
                        userId={props.user.id}
                        data={data}
                      />
                    ))}
                  </TableBody>
                </ListTable>
              </OrderGroup>
            )
          })}
        </OrderGroups>
      ) : (
        <Notice>There are no upcoming orders for this user</Notice>
      )}

      {!deactivatedAccount && props.pausedSubscriptions && (
        <Notice>This user's plan has been paused.</Notice>
      )}

      {deactivatedAccount && <Notice>This user is deactivated/cancelled.</Notice>}
    </Wrapper>
  )
}

function useController(props: Props) {
  const isProduction = useIsEnvironment(NodeEnv.production)
  const isCadenceSyncEnabled = useFeatureFlagEnableCadenceSync()
  const shouldShowActionsForm = !isProduction || isCadenceSyncEnabled
  const deactivatedAccount = props.user.deletedAt
  const data = useDataLoader(props.user)
  const loader = useLoader(data.giftProductsLoading)
  const isSuperAdmin = useHasUserRole([UserRole.superadmin])
  const areAllOrdersFromFirstWeekCharged = data.groupedOrders[0]?.orders?.every(
    (order: any) => order.chargedAt,
  )

  function hasMultipleKidsOrders(orders: any): boolean {
    const kidsIds = uniqBy(orders, (order) => order.kidId)
    return kidsIds.length > 1
  }

  return {
    shouldShowActionsForm,
    deactivatedAccount,
    data,
    groupedOrders: data.groupedOrders,
    loader,
    isSuperAdmin,
    giftProductsLoading: data.giftProductsLoading,
    hasMultipleKidsOrders,
    upcomingWeekTotal:
      data?.upcomingWeekOrdersSummary?.amountToChargeCents != null
        ? convertCentsToDollars(data?.upcomingWeekOrdersSummary.amountToChargeCents)
        : null,
    secondUpcomingWeekTotal:
      data?.secondUpcomingWeekOrdersSummary?.amountToChargeCents != null
        ? convertCentsToDollars(data?.secondUpcomingWeekOrdersSummary.amountToChargeCents)
        : null,
    areAllOrdersFromFirstWeekCharged,
  }
}
