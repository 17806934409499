import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrap = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow: hidden;
  width: 100%;
  max-width: 100vw;

  ${media.lg} {
    width: calc(100vw - 22.6rem);
    max-width: calc(100vw - 22.6rem);
  }
`

export const Body = styled.div`
  flex-grow: 1;
  overflow: auto;
`

export const Container = styled.div`
  max-width: 100%;
  padding: 0 20px 70px 20px;
  margin: 50px auto 0 auto;

  ${media.lg} {
    padding: 0 70px 70px 70px;
  }
`
