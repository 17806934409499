import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Content = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.midGray};
  padding: 0 1rem 2.5rem;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.darkGray};
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
  }

  ${media.lg} {
    width: 100rem;
  }
`

export const DialogHeader = styled.header`
  padding: 2.5rem 2.5rem 0;
  position: relative;
  border-radius: inherit;
`

export const Heading = styled.h2<{ noTopMargin?: boolean }>`
  font-size: 2.25rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: ${({ noTopMargin }) => (noTopMargin ? '0' : '70px')};
  color: ${(props) => props.theme.color.darkGray};
  margin-bottom: 0.8rem;
`

export const SubTitle = styled.h6`
  font-size: 1.2rem;
  margin-top: 0;
  color: ${(props) => props.theme.color.darkGray};
  text-align: left;
`

export const Products = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  font-size: 1.5rem;
`

export const Product = styled.li`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const ButtonsRow = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const PlusButton = styled.button`
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background-color: ${(props) => props.theme.color.green};
  border: none;
  color: ${(props) => props.theme.color.white};

  &:disabled {
    opacity: 0.5;
  }
`

export const MinusButton = styled(PlusButton)`
  background-color: ${(props) => props.theme.color.red};
`

export const TableWrapper = styled.div`
  max-width: 72.5rem;
  margin: 0 auto;
  margin-top: 2rem;
`

export const RefundSummary = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 4rem;
`

export const TitleText = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 45rem;
`

export const CustomRefundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`
