import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import media from '~/app/utils/mediaQueries'
import { switchOn } from '~/app/utils/switchOn'

export const Wrapper = styled.div``

export const Header = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  align-items: flex-end;

  &::-webkit-scrollbar {
    display: none;
  }
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  ${media.lg} {
    justify-content: center;
    overflow-x: visible;
    align-items: center;
  }
`

export const StyledTab = styled.li<{ active: boolean }>`
  ${(props) => css`
    flex-shrink: 0;
    color: ${props.theme.color.darkGray};
    cursor: pointer;
    font-size: 1.2rem;
    letter-spacing: 2px;
    padding: 1.4rem 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${props.theme.color.green};
      transform: scaleX(0);
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      color: ${(props) => props.theme.color.green};
    }

    ${switchOn(
      props.active &&
        css`
          color: ${props.theme.color.green};
          &::after {
            transform: scaleX(1);
          }
        `,
    )}

    ${media.lg} {
      font-size: 14px;
      letter-spacing: 2.5px;
      padding: 2rem 2.4rem;
    }
  `}
`

export const TabList = styled.ul`
  display: flex;
  padding: 0.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${(props) => props.theme.color.green};
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.lg} {
    width: 50%;
    overflow-x: visible;
  }
`

export const StyledContentTab = styled.li<{ isActive?: boolean }>`
  flex: 0 0 auto;
  padding: 0.4rem 0.6rem;
  font-size: 0.9rem;
  white-space: nowrap;
  text-align: center;
  border-radius: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  list-style-type: none;
  background: ${(props) => (props.isActive ? props.theme.color.paleLightGreen : 'transparent')};

  &:hover {
    background: ${(props) => props.theme.color.paleLightGreen};
  }

  ${media.lg} {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
`

export const Content = styled.div`
  width: 100%;
  overflow-x: scroll;
  max-width: 100%;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  min-width: 10rem;

  ${media.lg} {
    min-width: unset;
  }
`
